<template>
    <div
        class="bg-gradient-to-br from-soft-peach via-white to-rose-gold min-h-screen flex items-center justify-center p-4 font-sans text-deep-plum">
        <div class="absolute inset-0 overflow-hidden">
            <div
                class="absolute -top-24 -left-24 w-96 h-96 bg-rose-gold rounded-full mix-blend-multiply filter blur-xl opacity-30 animate-float">
            </div>
            <div class="absolute top-1/2 -right-24 w-96 h-96 bg-soft-peach rounded-full mix-blend-multiply filter blur-xl opacity-30 animate-float"
                style="animation-delay: 2s;"></div>
            <div class="absolute -bottom-24 left-1/2 w-96 h-96 bg-deep-plum rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-float"
                style="animation-delay: 4s;"></div>
        </div>

        <div
            class="relative glass bg-white bg-opacity-20 rounded-3xl shadow-2xl p-8 max-w-4xl w-full mx-auto overflow-hidden">
            <div class="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-rose-gold via-soft-peach to-deep-plum">
            </div>

            <div class="text-center mb-12 relative">
                <div class="inline-block relative mb-4">
                    <img src="../assets/logo.png" alt="AnRos Beauty Logo" class="w-48 h-auto">
                    <span
                        class="absolute -top-6 -right-6 w-12 h-12 bg-soft-peach rounded-full mix-blend-multiply filter blur-md animate-pulse-slow"></span>
                </div>
                <h2 class="text-2xl text-rose-gold mb-4 font-sans font-light tracking-wide">B E A U T Y</h2>
                <p class="text-lg text-deep-plum/80 max-w-2xl mx-auto font-sans">
                    Prepárate para descubrir una selección única de productos de maquillaje de las mejores marcas.
                    Encuentra todo lo que necesitas para resaltar tu belleza con calidad y estilo.
                </p>
            </div>

            <div class="mb-12 relative">
                <div class="absolute inset-0 bg-gradient-to-r from-rose-gold/20 to-soft-peach/20 transform -skew-y-6">
                </div>
                <div class="relative flex flex-col justify-center items-center py-8">
                    <!-- Formulario de suscripción -->
                    <form @submit.prevent="handleSubscribe"
                        class="flex flex-col sm:flex-row sm:items-center sm:justify-center w-full mb-4">
                        <!-- Campo de correo -->
                        <div class="mb-4 sm:mb-0">
                            <input type="email" v-model="email" placeholder="Tu correo electrónico"
                                class="px-6 py-3 w-full max-w-md sm:max-w-lg bg-white bg-opacity-50 border-2 border-rose-gold/30 rounded-full focus:outline-none focus:ring-2 focus:ring-deep-plum focus:border-transparent transition duration-300 ease-in-out placeholder-deep-plum/50"
                                required />
                        </div>

                        <!-- Botón de suscripción -->
                        <button type="submit"
                            class="bg-deep-plum text-white px-8 py-3 sm:ml-4 rounded-full hover:bg-rose-gold transition duration-300 ease-in-out transform hover:scale-105 font-sans font-semibold text-sm uppercase tracking-wider">
                            Notifícame
                        </button>
                    </form>

                    <!-- Mensaje de éxito o error -->
                    <div v-if="message" class="mt-4 text-lg text-center text-deep-plum">
                        <p>{{ message }}</p>
                    </div>
                </div>

                <!-- Iconos de características -->
                <div class="flex justify-center space-x-8 mb-14">
                    <div class="text-center">
                        <div
                            class="w-16 h-16 bg-rose-gold rounded-full flex items-center justify-center mb-2 mx-auto transform transition duration-300 hover:scale-110 hover:bg-soft-peach group">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="h-8 w-8 text-white group-hover:text-deep-plum transition duration-300"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                        <p class="font-serif text-sm">Variedad</p>
                    </div>
                    <div class="text-center">
                        <div
                            class="w-16 h-16 bg-rose-gold rounded-full flex items-center justify-center mb-2 mx-auto transform transition duration-300 hover:scale-110 hover:bg-soft-peach group">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="h-8 w-8 text-white group-hover:text-deep-plum transition duration-300"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                            </svg>
                        </div>
                        <p class="font-serif text-sm">Calidad</p>
                    </div>
                    <div class="text-center">
                        <div
                            class="w-16 h-16 bg-rose-gold rounded-full flex items-center justify-center mb-2 mx-auto transform transition duration-300 hover:scale-110 hover:bg-soft-peach group">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="h-8 w-8 text-white group-hover:text-deep-plum transition duration-300"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <p class="font-serif text-sm">Mejores Precios</p>
                    </div>
                </div>

                <div class="text-center ">
                    <p class="text-sm text-deep-plum/70 mb-4">Síguenos para las últimas actualizaciones</p>
                    <div class="flex justify-center space-x-6">
                        <!-- Enlace para Instagram -->
                        <a href="https://www.instagram.com/anrosbeautydo" target="_blank"
                            class="flex items-center justify-center text-deep-plum hover:text-rose-gold transition duration-300 p-2 rounded-full shadow-lg hover:shadow-xl">
                            <!-- Ícono de Instagram -->
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                    clip-rule="evenodd" />
                            </svg>
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import emailjs from "emailjs-com";


export default {
    data() {
        return {
            email: '',
            message: ''
        };
    },
    methods: {
        async handleSubscribe() {
            // Valida el formato del correo
            if (!this.validateEmail(this.email)) {
                this.message = "Por favor ingresa un correo válido.";
                return;
            }

            // Verifica si el correo ya está suscrito
            const isEmailExists = await this.checkEmailExists(this.email);
            if (isEmailExists) {
                this.message = "Este correo ya está suscrito.";
                return;
            }

            try {
                // Almacena el correo en Firestore
                const docRef = await addDoc(collection(db, "subscribers"), {
                    email: this.email,
                    subscribedAt: new Date(),
                });

                this.message = "¡Gracias por suscribirte!";
                console.log("Correo almacenado con ID:", docRef.id);

                // Envía el correo de confirmación al usuario
                await this.sendConfirmationEmail(this.email);

                this.email = ""; // Limpia el campo del correo
            } catch (e) {
                console.error("Error al almacenar el correo:", e);
                this.message = "Hubo un error al suscribirte, intenta más tarde.";
            }
        },

        async sendConfirmationEmail() {
            const templateParams = {
                to_email: this.email,
                subject: "¡Gracias por suscribirte!",
                message: `
                    Hola,
                    Gracias por suscribirte a nuestra lista de notificaciones. 
                    Pronto recibirás las mejores ofertas y novedades.
                    
                    ¡Estamos emocionados de que formes parte de nuestra comunidad!
                    
                    Atentamente, el equipo de AnRos Beauty.
                `
            };

            try {
                await emailjs.send(
                    "service_75n6o85", // Reemplaza con el ID del servicio EmailJS
                    "template_0yky6tg", // Reemplaza con el ID de la plantilla
                    templateParams,
                    "lSM8Osg2d84QQNL2p" // Reemplaza con tu User ID de EmailJS
                );
                console.log("Correo de confirmación enviado a:", this.email);
            } catch (error) {
                console.error("Error al enviar el correo:", error);
            }
        },

        async checkEmailExists(email) {
            // Realiza una consulta para verificar si el correo ya existe en la colección "subscribers"
            const q = query(collection(db, "subscribers"), where("email", "==", email));
            const querySnapshot = await getDocs(q);
            return !querySnapshot.empty; // Si la consulta no está vacía, el correo ya existe
        },

        validateEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        }
    }
};
</script>

<style scoped>
/* Estilos para la animación */
@keyframes float {
    0% {
        transform: translateY(-10px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes pulse-slow {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.animate-float {
    animation: float 4s ease-in-out infinite;
}

.animate-pulse-slow {
    animation: pulse-slow 2s ease-in-out infinite;
}
</style>