// Importa las funciones necesarias de Firestore
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBaJ18SJC3aclq_J-rLpXQToaIb0PiDWpc",
  authDomain: "subscripciones-firebase.firebaseapp.com",
  projectId: "subscripciones-firebase",
  storageBucket: "subscripciones-firebase.firebasestorage.app",
  messagingSenderId: "971413614028",
  appId: "1:971413614028:web:4525337b78747640b00169",
  measurementId: "G-CT6VEWNPG0"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Obtén la referencia de Firestore
const db = getFirestore(app);

// Exporta la referencia de la base de datos para usarla en otros archivos
export { db, collection, addDoc };
