<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-50 via-pink-50 to-rose-50 py-16 px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-16 lg:items-start">
        <!-- Imagen del producto -->
        <div class="relative">
          <div class="aspect-w-1 aspect-h-1 rounded-3xl bg-gray-100 overflow-hidden shadow-xl">
            <img :src="selectedImage" :alt="product.name" class="w-full h-full object-center object-cover" />
          </div>
          <div class="mt-6 grid grid-cols-4 gap-4">
            <button
              v-for="(image, index) in product.gallery"
              :key="index"
              @click="selectedImage = image"
              class="relative rounded-xl overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
              :class="{ 'ring-2 ring-pink-500': selectedImage === image }"
            >
              <img :src="image" :alt="`${product.name} - Imagen ${index + 1}`" class="w-full h-full object-center object-cover" />
            </button>
          </div>
        </div>

        <!-- Información del producto -->
        <div class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">{{ product.name }}</h1>
          <div class="mt-3 flex items-center">
            <p class="text-3xl text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 font-bold">${{ product.price.toFixed(2) }}</p>
            <div class="ml-4 pl-4 border-l border-gray-300">
              <h3 class="sr-only">Reseñas</h3>
              <div class="flex items-center">
                <div class="flex items-center">
                  <StarIcon v-for="rating in 5" :key="rating" :class="[product.rating >= rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
                </div>
                <p class="sr-only">{{ product.rating }} out of 5 stars</p>
                <a href="#" class="ml-3 text-sm font-medium text-pink-600 hover:text-pink-500">{{ product.reviewCount }} reseñas</a>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <h3 class="sr-only">Descripción</h3>
            <div class="text-base text-gray-700 space-y-6" v-html="product.description"></div>
          </div>

          <!-- Selección de color -->
          <div class="mt-8">
            <h3 class="text-sm font-medium text-gray-900">Color</h3>
            <div class="mt-3 flex items-center space-x-3">
              <button
                v-for="color in product.colors"
                :key="color.name"
                @click="selectedColor = color"
                class="relative p-0.5 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                :class="{ 'ring-2 ring-pink-500': selectedColor === color }"
              >
                <span
                  aria-hidden="true"
                  :style="{ backgroundColor: color.hex }"
                  class="h-8 w-8 border border-black border-opacity-10 rounded-full"
                ></span>
                <span class="sr-only">{{ color.name }}</span>
              </button>
            </div>
            <p class="mt-2 text-sm text-gray-500">Color seleccionado: {{ selectedColor.name }}</p>
          </div>

          <div class="mt-8">
            <div class="flex items-center">
              <CheckIcon class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
              <p class="ml-3 text-base text-gray-500">En stock y listo para enviar</p>
            </div>
          </div>

          <div class="mt-10 flex flex-col space-y-4">
            <button type="submit" class="w-full bg-gradient-to-r from-purple-600 to-pink-600 border border-transparent rounded-full py-4 px-8 flex items-center justify-center text-lg font-semibold text-white hover:from-purple-700 hover:to-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl">
              Añadir al carrito
            </button>
            <button type="button" class="w-full bg-pink-100 text-pink-700 py-4 px-8 rounded-full flex items-center justify-center text-lg font-semibold hover:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 transition-all duration-300">
              <HeartIcon class="h-6 w-6 flex-shrink-0 mr-2" aria-hidden="true" />
              <span>Añadir a favoritos</span>
            </button>
          </div>

          <!-- Métodos de pago aceptados -->
          <div class="mt-8">
            <h3 class="text-sm font-medium text-gray-900 mb-4">Métodos de pago aceptados</h3>
            <div class="flex space-x-4">
              <img src="/placeholder.svg?height=30&width=50&text=Visa" alt="Visa" class="h-8" />
              <img src="/placeholder.svg?height=30&width=50&text=MasterCard" alt="MasterCard" class="h-8" />
              <img src="/placeholder.svg?height=30&width=50&text=American Express" alt="American Express" class="h-8" />
              <img src="/placeholder.svg?height=30&width=50&text=PayPal" alt="PayPal" class="h-8" />
            </div>
          </div>

          <section aria-labelledby="details-heading" class="mt-12">
            <h2 id="details-heading" class="sr-only">Detalles adicionales</h2>
            <div class="border-t border-gray-200">
              <Disclosure as="div" v-slot="{ open }" v-for="(detail, index) in product.details" :key="index" class="border-b border-gray-200">
                <h3>
                  <DisclosureButton class="group relative w-full py-6 flex justify-between items-center text-left">
                    <span :class="[open ? 'text-pink-600' : 'text-gray-900', 'text-lg font-medium']">
                      {{ detail.name }}
                    </span>
                    <span class="ml-6 flex items-center">
                      <PlusIcon v-if="!open" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      <MinusIcon v-else class="block h-6 w-6 text-pink-400 group-hover:text-pink-500" aria-hidden="true" />
                    </span>
                  </DisclosureButton>
                </h3>
                <DisclosurePanel as="div" class="pb-6">
                  <ul role="list" class="list-disc pl-4 space-y-2 text-base text-gray-600">
                    <li v-for="item in detail.items" :key="item">{{ item }}</li>
                  </ul>
                </DisclosurePanel>
              </Disclosure>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { StarIcon, HeartIcon, CheckIcon, PlusIcon, MinusIcon } from 'lucide-vue-next'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

const product = ref({
  name: 'Labial Mate Duradero',
  price: 24.99,
  rating: 4,
  reviewCount: 117,
  image: 'https://images.unsplash.com/photo-1586495777744-4413f21062fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
  gallery: [
    'https://images.unsplash.com/photo-1586495777744-4413f21062fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    'https://images.unsplash.com/photo-1512496015851-a90fb38ba796?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    'https://images.unsplash.com/photo-1591360236480-4ed861025fa1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    'https://images.unsplash.com/photo-1599733594230-5cc2bdaf8732?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
  ],
  colors: [
    { name: 'Rojo Pasión', hex: '#FF0000' },
    { name: 'Rosa Suave', hex: '#FFC0CB' },
    { name: 'Coral Brillante', hex: '#FF7F50' },
    { name: 'Nude Elegante', hex: '#DEB887' },
  ],
  description: `
    <p class="mb-4">Nuestro Labial Mate Duradero es la elección perfecta para aquellas que buscan un color intenso y duradero. Formulado con ingredientes hidratantes, este labial no solo ofrece un acabado mate impecable, sino que también cuida tus labios.</p>
    <p>Su fórmula ligera y de larga duración te permitirá lucir unos labios perfectos durante todo el día, sin necesidad de retoques constantes. Disponible en una amplia gama de tonos para complementar cualquier look o tono de piel.</p>
  `,
  details: [
    {
      name: 'Características',
      items: [
        'Acabado mate de larga duración',
        'Fórmula hidratante',
        'No transfiere',
        'Disponible en 20 tonos',
        'Vegano y libre de crueldad animal'
      ]
    },
    {
      name: 'Ingredientes',
      items: [
        'Cera de Carnauba',
        'Aceite de Jojoba',
        'Vitamina E',
        'Manteca de Karité',
        'Pigmentos minerales'
      ]
    },
    {
      name: 'Cómo aplicar',
      items: [
        'Exfolia suavemente los labios antes de la aplicación',
        'Aplica una capa fina y uniforme desde el centro hacia las comisuras',
        'Para mayor intensidad, aplica una segunda capa',
        'Utiliza un delineador de labios para un acabado más definido'
      ]
    }
  ]
})

const selectedImage = ref(product.value.gallery[0])
const selectedColor = ref(product.value.colors[0])
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>

