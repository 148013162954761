// Importación de las vistas (o usa lazy loading para optimizar)

import FormView from '@/pages/FormView.vue';
import ProductDetailView from '@/pages/ProductDetailView.vue';
import ProductsView from '@/pages/ProductsView.vue';


export const routes = [
  {
    path: '/',
    name: 'FormView',
    component: FormView,
  },
  {
    path: '/detail',
    name: 'ProductDetailView',
    component: ProductDetailView,
  },
  {
    path: '/products',
    name: 'ProductsView',
    component: ProductsView,
  },
//   {
//     path: '/:pathMatch(.*)*', // Ruta para manejar páginas no encontradas
//     name: 'NotFound',
//     component: () => import('@/pages/NotFound.vue'),
//   },
];
