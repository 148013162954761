<template>
  <div id="app">
    <router-view></router-view> <!-- Esto es donde las rutas se renderizarán -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>
