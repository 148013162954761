// Ejemplo de un guardia global
export const globalGuard = (to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('token'); // Cambia esta lógica según tu sistema de autenticación
  
    if (to.meta.requiresAuth && !isAuthenticated) {
      // Redirige a la página de login si no está autenticado
      next({ name: 'Login' });
    } else if (to.name === 'Login' && isAuthenticated) {
      // Evita que un usuario autenticado acceda al login
      next({ name: 'Dashboard' });
    } else {
      // Permite la navegación
      next();
    }
  };
  