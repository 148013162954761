<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-50 via-pink-50 to-rose-50 py-16 px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
      <h1 class="text-5xl md:text-6xl font-extrabold text-center mb-16">
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">Descubre tu
          belleza</span>
      </h1>

      <div class="flex flex-col lg:flex-row gap-12">
        <!-- Filtros -->
        <div class="w-full lg:w-1/4">
          <div class="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg rounded-3xl shadow-xl p-8 sticky top-8">
            <h2 class="text-3xl font-semibold text-gray-800 mb-8">Filtros</h2>

            <!-- Categoría -->
            <div class="mb-10">
              <h3 class="text-xl font-medium text-gray-700 mb-4">Categoría</h3>
              <div class="space-y-3">
                <label v-for="category in categories" :key="category" class="flex items-center">
                  <input type="checkbox" :value="category" v-model="selectedCategories"
                    class="form-checkbox h-5 w-5 text-pink-600 rounded-full border-gray-300 focus:ring-pink-500 transition duration-300 ease-in-out" />
                  <span class="ml-3 text-gray-700">{{ category }}</span>
                </label>
              </div>
            </div>

            <!-- Precio -->
            <div class="mb-10">
              <h3 class="text-xl font-medium text-gray-700 mb-4">Precio</h3>
              <div class="flex items-center space-x-4">
                <input type="number" v-model="minPrice" placeholder="Min"
                  class="w-full px-4 py-2 placeholder-gray-400 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-pink-500 transition duration-300 ease-in-out" />
                <span class="text-gray-500">-</span>
                <input type="number" v-model="maxPrice" placeholder="Max"
                  class="w-full px-4 py-2 placeholder-gray-400 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-pink-500 transition duration-300 ease-in-out" />
              </div>
            </div>

            <!-- Valoración -->
            <div>
              <h3 class="text-xl font-medium text-gray-700 mb-4">Valoración</h3>
              <div class="space-y-3">
                <label v-for="rating in [4, 3, 2, 1]" :key="rating" class="flex items-center">
                  <input type="radio" :value="rating" v-model="selectedRating"
                    class="form-radio h-5 w-5 text-pink-600 border-gray-300 focus:ring-pink-500 transition duration-300 ease-in-out" />
                  <span class="ml-3 flex items-center">
                    <StarIcon v-for="star in rating" :key="star" class="h-5 w-5 text-yellow-400" />
                    <StarIcon v-for="star in 5 - rating" :key="`empty-${star}`" class="h-5 w-5 text-gray-300" />
                    <span class="ml-2 text-gray-700">y más</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Lista de productos -->
        <div class="w-full lg:w-3/4">
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            <div v-for="product in filteredProducts" :key="product.id" class="bg-white rounded-3xl overflow-hidden shadow-lg transition-all duration-300 transform hover:scale-105 hover:shadow-xl flex flex-col">
              <div class="relative">
                <img :src="product.image" :alt="product.name" class="w-full h-48 object-cover transition-transform duration-500 hover:scale-110" />
                <div class="absolute top-0 right-0 bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold rounded-bl-3xl py-2 px-4 shadow-lg">
                  <p class="text-xl font-extrabold">${{ product.price.toFixed(2) }}</p>
                </div>
              </div>
              <div class="flex flex-col flex-grow p-6">
                <div class="flex-grow">
                  <h3 class="font-semibold text-xl mb-2 text-gray-900">{{ product.name }}</h3>
                  <p class="text-gray-600 mb-3">{{ product.category }}</p>
                  <div class="flex items-center mb-3">
                    <StarIcon v-for="star in Math.floor(product.rating)" :key="star" class="h-5 w-5 text-yellow-400" />
                    <StarIcon v-for="star in 5 - Math.floor(product.rating)" :key="`empty-${star}`" class="h-5 w-5 text-gray-300" />
                    <span class="ml-2 text-sm text-gray-600">({{ product.reviews }})</span>
                  </div>
                </div>
                <div class="flex justify-between items-center mt-4">
                  <button class="flex-grow bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 shadow-md hover:shadow-lg">
                    Añadir al carrito
                  </button>
                  <button class="ml-2 text-pink-600 hover:text-pink-700 transition-colors duration-300">
                    <HeartIcon class="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { StarIcon, HeartIcon } from 'lucide-vue-next'

const categories = ['Labios', 'Ojos', 'Rostro', 'Accesorios']
const selectedCategories = ref([])
const minPrice = ref(null)
const maxPrice = ref(null)
const selectedRating = ref(null)

const products = ref([
  { id: 1, name: "Labial Mate Duradero", category: "Labios", price: 24.99, rating: 4.5, reviews: 117, image: "https://images.unsplash.com/photo-1586495777744-4413f21062fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80" },
  { id: 2, name: "Paleta de Sombras Luxury", category: "Ojos", price: 49.99, rating: 4.8, reviews: 89, image: "https://images.unsplash.com/photo-1512496015851-a90fb38ba796?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80" },
  { id: 3, name: "Base Líquida Perfeccionadora", category: "Rostro", price: 34.99, rating: 4.2, reviews: 156, image: "https://images.unsplash.com/photo-1617897903246-719242758050?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80" },
  { id: 4, name: "Máscara de Pestañas Voluminizadora", category: "Ojos", price: 19.99, rating: 4.6, reviews: 203, image: "https://images.unsplash.com/photo-1631214524020-7e18db9a8f92?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80" },
  { id: 5, name: "Iluminador en Polvo", category: "Rostro", price: 29.99, rating: 4.4, reviews: 78, image: "https://images.unsplash.com/photo-1596704017254-9b121068fb31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80" },
  { id: 6, name: "Brochas de Maquillaje Set", category: "Accesorios", price: 39.99, rating: 4.7, reviews: 92, image: "https://images.unsplash.com/photo-1596462502278-27bfdc403348?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80" },
])

const filteredProducts = computed(() => {
  return products.value.filter(product => {
    const categoryMatch = selectedCategories.value.length === 0 || selectedCategories.value.includes(product.category)
    const priceMatch = (!minPrice.value || product.price >= minPrice.value) && (!maxPrice.value || product.price <= maxPrice.value)
    const ratingMatch = !selectedRating.value || product.rating >= selectedRating.value
    return categoryMatch && priceMatch && ratingMatch
  })
})
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }

  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}
</style>
