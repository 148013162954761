import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes'; // Importamos las rutas definidas en routes.js
import { globalGuard } from './guards'; // Importamos los guardias globales

const baseUrl = process.env.VUE_APP_BASE_URL || '/'; // Usa la variable de entorno si existe, sino usa '/'

const router = createRouter({
  history: createWebHistory(baseUrl), // Usa baseUrl que es / o el valor de la variable
  routes, // Rutas importadas
});

// Agregar guardias globales
router.beforeEach(globalGuard);

export default router;
