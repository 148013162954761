import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Importamos el router configurado
import './styles/tailwind.css'; // Estilos globales con Tailwind CSS


// Crea la instancia de la aplicación
const app = createApp(App);

// Usa el router en la aplicación
app.use(router);

// Monta la aplicación en el elemento con ID `app`
app.mount('#app');
